import React, { useState } from 'react';
import './ValuationRow.css';
import PDF_icon from '../assets/icons/PDF_icon.svg';
import DeleteScreen from './DeleteScreen';
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import './EditScreen.css';

function ValuationRow({ val, currentUser, getValuationData }) {
    const [renderDelete, setRenderDelete] = useState(false)
    const date = (new Date(Date.parse(val.dateCreated))).toLocaleDateString('no-NO');

    const navigate = useNavigate()
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleAgree = () => {
        // navigate to the edit component with the valuationObject as a state
        navigate('/rediger', { state: { valuationObject: val } });
    }

    const toggleDelete = () => {
        getValuationData();
        setRenderDelete(!renderDelete);
    }

    return (
        <tr className="Valuations-row">
            <td>{date}</td>
            <td>{val.firm}</td>
            <td>{val.firstName} {val.lastName}</td>
            <td>{val.address} {val.addressNumber} {val.addressLetter}</td>
            <td>{val.rent} {val.rentHighEstimate ? " - " + val.rentHighEstimate : null}</td>
            <td>
                {currentUser && currentUser.userPrincipalName === val.userPrincipalName ?
                    <Button className="edit-button" variant="link" style={{ padding: '10px', transition: 'color 0.3s ease' }}
                        onMouseOver={e => e.currentTarget.style.color = '#007BFF'}
                        onMouseOut={e => e.currentTarget.style.color = ''}
                        onClick={handleShow}>
                        <i style={{ fontSize: "24px" }} className="fa">&#xf040;</i>
                    </Button>
                    : null}
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="my-modal"
                    backdropClassName="my-backdrop"
                >
                    <Modal.Header>
                        <Modal.Title>Obs!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Redigeringsfunksjonen er kun ment for å rette opp i skrivefeil i et leieprisestimat som ikke har blitt sendt ut. Hvis du skal lage et nytt leieprisestimat for et objekt som tidligere har blitt vurdert skal du opprette et nytt dokument.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="my-modal-button" onClick={handleClose}>
                            <p className="my-modal-button-text">Avbryt</p>
                        </Button>
                        <Button variant="primary" className="my-modal-button" onClick={handleAgree}>
                            <p className="my-modal-button-text">Rediger leieprisestimat</p>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </td>
            <td >
                <a href={val.valuationUrl} target="_blank" rel="noopener noreferrer">
                    <img src={PDF_icon} className="PDF-icon" alt="PDF-icon" />
                </a>
            </td>
            {currentUser && currentUser.userPrincipalName === val.userPrincipalName ? <td> <button className="Valuation-row-button" onClick={() => setRenderDelete(!renderDelete)}><p>Slett</p></button> </td>
                :
                null}
            <td>
                {renderDelete ?
                    <DeleteScreen val={val} setDelete={toggleDelete} />
                    :
                    null
                }
            </td>
        </tr>
    )
}

export default ValuationRow;