import './App.css';
import Navbar from './components/Navbar';
import { Outlet } from "react-router-dom";
import React, { useState, useEffect, createContext } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { getUser } from './lib/userService';

export const AccessContext = createContext();

function App() {
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();
    const [user, setUser] = useState();

    async function handleLogin() {
        await instance.handleRedirectPromise();
        if (!isAuthenticated) {
            await instance.loginRedirect();
        }
    }

    async function fetchUserData() {
        const userData = await getUser();
        setUser(userData);
    }

    useEffect(() => {
        if (isAuthenticated) {
            fetchUserData();
        }
    }, [isAuthenticated, inProgress, instance]);

    return (
        <div className="App">
            <Navbar />
            {isAuthenticated ? 
                <Outlet context={[user, setUser]} />
                :
                <div className="app--login--container">
                    <button variant="secondary" className="app--login--button" onClick={handleLogin}>
                        Logg deg inn
                    </button>
                </div>
            }
        </div>
    );
}

export default App;