import './Navbar.css';
import logo from '../assets/icons/logo.svg';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { BurgerMenu } from './Menu/BurgerMenu';
import Drawer from './Menu/Drawer';
import UserCard from './UserCard';

function Navbar() {
    const [open, setOpen] = useState(false);

    return (
        <nav>
            <div className="navbar">
                <UserCard />
                <div className="logo">
                    <Link to ="/">
                        <img src={logo} className="navbar-logo" alt="logo" />
                    </Link>
                </div>
                <BurgerMenu open={open} setOpen={setOpen} />
                <Drawer open={open} setOpen={setOpen} />
            </div>
        </nav>
    );
}

export default Navbar;
