import { msalInstance } from "./authConfig"; 

export async function getAccessToken(scopes) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        const request = {
            account: accounts[0],
            scopes: scopes
        };

        try {
            const response = await msalInstance.acquireTokenSilent(request);
            return response.accessToken;
        } catch (error) {
            throw new Error('Failed to acquire token silently', error);
        }
    }
}