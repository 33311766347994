import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar'
import SuccessScreen from './SuccessScreen';
import './ValuationForm.css';
import './RentPopup.css';
import { useOutletContext } from "react-router-dom";
import { isTablet } from 'react-device-detect';
import 'react-phone-input-2/lib/style.css';
import { useLocation } from 'react-router-dom';
import DropDown from './DropDown';
import PhoneInputField from './PhoneInputField';
import { submitValuation, propertyMappings } from '../../lib/valuationsService';

function ValuationEditForm() {
    const location = useLocation();
    const valuationToEdit = location.state?.valuationObject || {};
    const [activeAddress, setActiveAddress] = useState({});
    const [displayWarning, setDisplayWarning] = useState(false);
    const [formData, setFormData] = useState(valuationToEdit);
    const [phoneCountryCode, setPhoneCountryCode] = useState(formData.phoneCountryCode);
    const [phoneNumber, setPhoneNumber] = useState(formData.phone || '');
    const [response, setResponse] = useState({});
    const [search, setSearch] = useState('');
    const [sendEmail, setSendEmail] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [user] = useOutletContext();
    const [valuationUrl, setValuationUrl] = useState(false);

    useEffect(() => {
        if (Object.keys(activeAddress).length === 0) {
            return;
        }

        const newFormData = { ...formData };
        newFormData.lnr = '';
        Object.entries(propertyMappings).forEach(([key, value]) => {
            newFormData[value] = activeAddress[key];
        })
        setFormData(newFormData);
    }, [activeAddress]);

    const handleCheckboxChange = (event) => {
        setSendEmail(event.target.checked);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (parseInt(formData.rent) >= parseInt(formData.rentHighEstimate)) {
            setDisplayWarning(true);
            setShowPopup(true);
            return;
        }

        setSubmitted(true);
        submitValuation(formData, phoneNumber, phoneCountryCode, user, isTablet, sendEmail, true)
            .then(res => {
                setValuationUrl(res.valuationUrl);
                setResponse(res);
        });
    }

    const Popup = ({ onClose }) => (
        <div className='Popup-background' onClick={onClose}>
            <div className='Popup-body'>
                <h3>Maks leie må være høyere enn månedsleie</h3>
                <button className='Popup--button' onClick={onClose}>
                    <p>Lukk</p>
                </button>
            </div>
        </div>
    );

    if (valuationToEdit.rent == null) {
        return (
            <div className='valuation-form--wrapper'>
                <h1>Noe gikk galt med å hente leieprisestimat til dette skjemaet. Naviger tilbake til listen og prøv igjen, pass på å bruke redigeringsknappen. Hvis det ikke fungerer ta kontakt med support</h1>
            </div>
        )
    }
    
    return (
        <div className='valuation-form--wrapper'>
            <form className='valuation-form' onSubmit={handleSubmit}>
                <h2>Rediger leieprisestimat</h2>

                <section className='valuation-form--section'>
                    <div className='valuation-form--input'>
                        <label htmlFor='officeLocation'>Filial</label> <input id='officeLocation' disabled={true} type='text' value={user && user.officeLocation} className='Valuation__form__field' />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='givenName'>Fornavn</label> <input id='givenName' disabled={true} type='text' value={user && user.givenName} className='Valuation__form__field' />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='surname'>Etternavn</label> <input id='surname' disabled={true} type='text' value={user && user.surname} className='Valuation__form__field' />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='mail'>Epost</label> <input id='mail' disabled={true} type='text' value={user && user.mail} className='Valuation__form__field' />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='mobilePhone'>Tlf</label> <input id='mobilePhone' disabled={true} type='text' value={user && user.mobilePhone} className='Valuation__form__field' />
                    </div>
                </section>

                <section className='valuation-form--section'>
                    <div className='valuation-form--input'>
                        <label htmlFor='firm'>Mottaker: Firma</label> <input id='firm' type='text' disabled={submitted} value={formData.firm || ''} name='firm' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='first-name'>Mottaker: Fornavn</label> <input id='first-name' type='text' disabled={submitted} value={formData.firstName || ''} name='firstName' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='last-name'>Mottaker: Etternavn</label> <input id='last-name' type='text' disabled={submitted} value={formData.lastName || ''} name='lastName' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='email'>Mottaker: Epost</label> <input id='email' type="email" disabled={submitted} value={formData.eMail || ''} name='eMail' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <PhoneInputField phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} phoneCountryCode={phoneCountryCode} setPhoneCountryCode={setPhoneCountryCode} submitted={submitted} />
                </section>

                <section className='valuation-form--section'>
                    <SearchBar activeAddress={activeAddress} setActiveAddress={setActiveAddress} search={search} setSearch={setSearch} />
                </section>
                <section className='valuation-form--section'>
                    <div className='valuation-form--input'>
                        <label htmlFor='address-name'>Adresse</label> <input id='address-name' type='text' disabled={submitted} value={formData.address || ''} name='address' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='address-number'>Husnr.</label> <input id='address-number' type='number' disabled={submitted} value={formData.addressNumber || ''} name='addressNumber' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='address-letter'>Husbokstav</label> <input id='address-letter' type='text' disabled={submitted} value={formData.addressLetter || ''} name='addressLetter' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='postal-number'>Postnr.</label> <input id='postal-number' type='number' disabled={submitted} value={formData.postalNumber || ''} name='postalNumber' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='postal-area'>Poststed</label> <input id='postal-area' type='text' disabled={submitted} value={formData.postalArea || ''} name='postalArea' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>
                </section>

                <section className='valuation-form--section'>
                    <div className='valuation-form--input'>
                        <label htmlFor='gardsnummer'>Gårdsnummer</label><input type='number' id='gardsnummer' disabled={submitted} value={formData.gnr || ''} name='gnr' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='bruksnummer'>Bruksnummer</label><input type='number' id='bruksnummer' disabled={submitted} value={formData.bnr || ''} name='bnr' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='festenummer'>Festenummer</label><input type='number' id='festenummer' disabled={submitted} value={formData.fnr || ''} name='fnr' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='seksjonsnummer'>Seksjonsnummer</label><input type='number' id='seksjonsnummer' disabled={submitted} value={formData.snr || ''} name='snr' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='andelsnummer'>Andelsnummer</label><input type='number' id='andelsnummer' disabled={submitted} value={formData.anr || ''} name='anr' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <div className='valuation-form--input'>
                        <label htmlFor='bruksenhetsnummer'>Bruksenhetsnummer</label>
                        <DropDown 
                            id='apartment'
                            items={activeAddress.bruksenhetsnummer}
                            initialValue={formData.lnr}
                            submitted={submitted}
                            onItemSelect={value => {
                                const pseudoEvent = {
                                    target: {
                                        name: 'lnr',  // The name of the property in formData to update
                                        value,  // The selected apartment
                                    },
                                };
                                handleInputChange(pseudoEvent);
                            }}
                        /> 
                    </div>
                </section>

                <section className='valuation-form--section'>
                    <div className='valuation-form--input'>
                        <label htmlFor='rent'>Månedsleie (Påkrevd)</label>
                        <input type='number' id='rent' disabled={submitted} value={formData.rent || ''} name='rent' className='Valuation__form__field' onChange={handleInputChange} />
                    </div>

                    <p className='valuation-form--input-p'>{"-"}</p>

                    <div className='valuation-form--input'>
                        <label htmlFor='rentHighEstimate'>Maks leie (valgfritt intervall)  {displayWarning && <i className="fa-solid fa-triangle-exclamation warning-icon"></i>}</label>
                        <input type='number' id='rentHighEstimate' disabled={!formData.rent || submitted} value={formData.rentHighEstimate} name='rentHighEstimate' className={`Valuation__form__field ${displayWarning && "input-error"}`} onChange={handleInputChange} />
                    </div>
                </section>

                <div className="flex-end-container">

                    {submitted ? null :
                        !formData.rent ? <div className='valuation-form--button--disabled'><p>Send</p></div>
                            : <input className='valuation-form--button' type='submit' value="Opprett" />}

                    {submitted ? null :
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                className="hidden-checkbox"
                                checked={sendEmail}
                                onChange={handleCheckboxChange}
                                id="emailCheckbox"
                            />
                            <label htmlFor="emailCheckbox" className="label-container">
                                {sendEmail
                                    ? <i className="fas fa-check-square fa-icon" />
                                    : <i className="far fa-square fa-icon" />
                                }
                                <div>Jeg ønsker å motta leieprisestimatet på epost</div>
                            </label>
                        </div>
                    }
                </div>

                {showPopup && (
                    <Popup onClose={() => setShowPopup(false)} />
                )}

                <SuccessScreen submitted={submitted} setSubmitted={setSubmitted} valuationUrl={valuationUrl} response={response} />

            </form>
        </div>
    )
}
export default ValuationEditForm;