import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Hvis du ser denne siden har du navigert til en side som ikke finnes, eller så har noe gått galt.</p>
            <p> Feilkode:
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}