import CallToActionButton from "./CallToActionButton"
import './Home.css'

const Home = () => {
    return (
        <div className="Home">
            <CallToActionButton text={"Lag et nytt leieprisestimat"} link={'nytt-estimat'} />
            <CallToActionButton text={"Se leieprisestimater"} link={'leieprisestimater'} />
        </div>
    )
}

export default Home;