import React, { useState, useEffect } from 'react';
import './SearchBar.css';
import { searchAddresses } from '../../lib/addressSearchService';

function SearchBar({ activeAddress, setActiveAddress, search, setSearch }) {

    const [resultList, setResultList] = useState([]);

    useEffect(() => {
        getSearchData(search);
        // eslint-disable-next-line
    }, [search]);

    const getSearchData = async search => {
        if (search.length === 0) {
            setResultList([]);
            return;
        }

        const addresses = await searchAddresses(search);
        if (addresses.length !== 0) {
            setResultList(addresses);
        }
    }

    const handleChooseAddress = (address) => {
        const newAddress = address;
        if (newAddress.festenummer === 0) {
            newAddress.festenummer = '';
        };
        setActiveAddress(newAddress);
        setResultList([]);
    }

    return (
        <div className='search-bar'>
            <h5 className="search-bar--header" htmlFor='search-bar--input'>Søk på adresse</h5>
            <input
                id='search-bar--input'
                className='search-input ticket__form__field'
                type='text'
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <div className='search--results'>
                {resultList && resultList.map(address =>
                    <p
                        className='search--row'
                        key={address.representasjonspunkt.lat * address.nummer}
                        onClick={() => handleChooseAddress(address)}
                    >
                        {address.adressetekst + ' ' + address.poststed}
                    </p>
                )}
            </div>
            {activeAddress.adressetekst &&
                <p className="search--results-active-address">
                    {`${activeAddress.adressetekst}, ${activeAddress.postnummer} ${activeAddress.poststed}`}
                </p>
            }
        </div>
    )
}

export default SearchBar;