import React from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';

const MenuButton = ({
  isOpen = false,
  width = 14,
  height = 14,
  strokeWidth = 1,
  color = '#000',
  transition = null,
  lineProps = null,
  ...props
}) => {
  const variant = isOpen ? 'opened' : 'closed';
  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: 2,
    },
  };
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -2,
    },
  };
  lineProps = {
    stroke: isOpen ? '#fff' : color,
    strokeWidth: strokeWidth,
    vectorEffect: 'non-scaling-stroke',
    initial: 'closed',
    animate: variant,
    transition,
    ...lineProps,
  };
  const unitHeight = 4;
  const unitWidth = (unitHeight * width) / height;

  return (
    <MenuButtonSVG
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={width}
      height={height}
      {...props}
    >
      <motion.line id="line" x1="0" x2={unitWidth} y1="0" y2="0" variants={top} {...lineProps} />
      <motion.line id="line" x1="0" x2={unitWidth} y1="2" y2="2" variants={center} {...lineProps} />
      <motion.line id="line" x1="0" x2={unitWidth} y1="4" y2="4" variants={bottom} {...lineProps} />
    </MenuButtonSVG>
  );
};

export { MenuButton };

const MenuButtonSVG = styled(motion.svg)`
  &:hover {
    #line {
      stroke: #FEC359;
    }
  }
`;
