import UserPlaceHolder from '../assets/icons/UserPlaceHolder.svg';
import './UserCard.css';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useState } from 'react';
import { getUserPhoto } from '../lib/userService';

function UserCard() {
    const isAuthenticated = useIsAuthenticated();
	const { accounts } = useMsal();
	const user = accounts[0];
	const [userImage, setUserImage] = useState(UserPlaceHolder);

    useEffect(() => {
        async function getImage() {
            const userPhoto = await getUserPhoto();
            setUserImage(userPhoto);
        }

        if (isAuthenticated)
        {
            getImage();
        }
    }, [isAuthenticated, userImage])

	return (
		<div className='user-card'>
			<img src={userImage} className="user-image" alt="Current user" />
			<p>{user && user.name}</p>
		</div>
	)
}

export default UserCard;
