import './Valuations.css';
import ValuationRow from './ValuationRow';
import { useState, useEffect, useRef } from 'react';
import OfficePicker from './OfficePicker';
import { useOutletContext } from "react-router-dom";
import { getValuations } from '../lib/valuationsService';

function Valuations() {
    const hasFetchedData = useRef(false);
    const [user] = useOutletContext();

    const [valuationList, setValuationList] = useState();
    const [activeOffice, setActiveOffice] = useState();
    const [officeList, setOfficeList] = useState();
    const [userList, setUserList] = useState();
    const [activeUser, setActiveUser] = useState();
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalValuations, setTotalValuations] = useState();
    const [valuationsPerDate, setValuationsPerDate] = useState({});

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (valuationList || []).slice(indexOfFirstItem, indexOfLastItem);

    const valuationsFilter = data => {
        let valuations = data;
        setTotalValuations(valuations.length);

        if (activeOffice) {
            valuations = data.filter(valuation => valuation.userOffice === activeOffice);
            userFilter(valuations);
            setTotalValuations(valuations.length);

        }
        if (activeUser) {
            valuations = valuations.filter(valuation => valuation.userFullName === activeUser);
            setTotalValuations(valuations.length);
            calcValuationsPerDate(valuations);
        }

        if (address && address.length > 0) {
            const addressLowerCase = address.toLowerCase();
            valuations = valuations.filter(valuation =>
                valuation.address?.toLowerCase().includes(addressLowerCase) ||
                valuation.addressNumber?.includes(addressLowerCase) ||
                valuation.addressLetter?.toLowerCase().includes(addressLowerCase));
        }

        setValuationList(valuations);
        setCurrentPage(1);
    }

    const calcValuationsPerDate = data => {
        const result = {
            thirtyDays: 0,
            oneYear: 0
        };

        data.forEach(element => {
            const date = new Date(element.dateCreated);
            const currentDate = new Date();
            const dateDif = (currentDate - date) / (1000 * 60 * 60 * 24);
            if (dateDif < 365)
                result.oneYear++;
            if (dateDif < 31)
                result.thirtyDays++;
        });
        setValuationsPerDate(result)
    }

    const officeFilter = data => {
        const offices = data.map(valuation => valuation.userOffice).filter(office => office != null);
        const uniqueOffices = [...new Set(offices)];

        setOfficeList(uniqueOffices);
    }

    const userFilter = data => {
        const users = data.map(valuation => valuation.userFullName);
        const uniqueUsers = [...new Set(users)];
        setUserList(uniqueUsers);
    }

    const currentUsersValuations = () => {
        setActiveUser(user.displayName);
        setActiveOffice();
        setUserList();
    }

    const getValuationData = async () => {
        const valuations = await getValuations();
        valuations.reverse();
        valuationsFilter(valuations);
        officeFilter(valuations);
        setLoading(false);
    }

    useEffect(() => {
        getValuationData();
        // eslint-disable-next-line
    }, [activeOffice, activeUser, address])

    useEffect(() => {
        if (!hasFetchedData.current) {
            getValuationData();
            hasFetchedData.current = true;
        }
    }, [valuationList])

    if (loading) {
        return (
            <div className="Valuations-container">
                <p className="Valuations-loading">Laster inn...</p>
            </div>
        )
    }

    return (
        <div className="Valuations-container">
            <div className="Valuations-sidebar">
                <button onClick={currentUsersValuations}>Mine leieprisestimater</button>
                {officeList && <OfficePicker officeList={officeList} activeOffice={activeOffice} setActiveOffice={setActiveOffice} setActiveUser={setActiveUser} setUserList={setUserList} />}
            </div>
            <div className="Valuations-main">
                <div className="Valuations-main-header">
                    <div className="Valuations-main-header-section">
                        <label>Velg ansatt</label>
                        <select id="dropdown" value={activeUser} onChange={value => { setActiveUser(value.target.value) }}>
                            <option value="">Velg ansatt</option>
                            {userList ? userList.map((user, index) => (<option key={index} value={user}>{user}</option>)) : null}
                        </select>
                    </div>
                    {activeUser ?
                        <div className="Valuations-main-header-section">
                            <label>{activeUser} sine leieprisestimater</label> <br />
                            <label>Total antall estimater: {totalValuations}</label> <br />
                            <label>Estimater siste 30 dagene: {valuationsPerDate ? valuationsPerDate.thirtyDays : "Ingen data"}</label> <br />
                            <label>Estimater siste året: {valuationsPerDate ? valuationsPerDate.oneYear : "Ingen data"}</label>
                        </div>
                        :
                        <div className="Valuations-main-header-section">
                            <label>Total antall estimater for {activeOffice ? activeOffice : "alle kontorer"}: {totalValuations}</label>
                        </div>
                    }

                    <div className="Valuations-main-header-section">
                        <label htmlFor="valuations-address-search">Søk i adresse</label>
                        <input
                            type="text"
                            id="valuations-address-search"
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                        />
                    </div>
                </div>
                <table className="Valuations-table">
                    <thead className="Valuations-header">
                        <tr>
                            <th className="Valuations-Header-Item">Brevdato</th>
                            <th className="Valuations-Header-Item">Firma</th>
                            <th className="Valuations-Header-Item customer-name-column">Kunde</th>
                            <th className="Valuations-Header-Item">Addresse</th>
                            <th className="Valuations-Header-Item">Månedsleie</th>
                            <th className="Valuatings-Header-Item">Rediger</th>
                            <th className="Valuations-Header-Item">PDF</th>
                            <th className="Valuations-Header-Item">Slett</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.map(val => <ValuationRow key={val.id} val={val} currentUser={user} getValuationData={getValuationData} />)}
                    </tbody>
                </table>
                <div className="Valuations-footer">
                    <button className="Valuations-nav-button" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Forrige side</button>
                    <button className="Valuations-nav-button" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(valuationList.length / itemsPerPage)}>Neste side</button>
                </div>
            </div>
        </div>
    );
}

export default Valuations;