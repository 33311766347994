
import { getAccessToken } from "./tokenService";

export const propertyMappings = {
    bruksnummer: 'bnr',
    gardsnummer: 'gnr',
    festenummer: 'fnr',
    seksjonsnummer: 'snr',
    andelsnummer: 'anr',
    rom: 'rooms',
    type: 'dwellingType',
    adressenavn: 'address',
    nummer: 'addressNumber',
    postnummer: 'postalNumber',
    bokstav: 'addressLetter',
    poststed: 'postalArea'
}

export async function submitValuation(formData, phoneNumber, phoneCountryCode, user, isTablet, sendEmail, edit) {
    const valuation = mapValuation(formData, phoneNumber, phoneCountryCode, user, isTablet, sendEmail, edit)
    const body = JSON.stringify(valuation);
    const url = `${process.env.REACT_APP_LOGIC_APP_URL}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            accept: 'text/plain',
            headers: { 'Content-Type': 'application/json' },
            body: body
        });

        const result = await response.json();

        if (!response.ok) {
            return {
                ...result,
                error: true,
                message: `Failed to post data: ${response.statusText}`
            };
        }

        return result; 
    } catch (error) {
        // In case of network errors or other unexpected issues
        return {
            error: true,
            message: error.message || "An unexpected error occurred while posting data."
        };
    }
}

export async function getValuations() {

    const accessToken = await getValuationApiAccessToken();

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    const requestOptions = {
        method: 'GET',
        headers: headers
    }

    try {
        const valuationsResponse = await fetch(process.env.REACT_APP_API, requestOptions);
        const valuations = await valuationsResponse.json();
        
        if (!valuationsResponse.ok) {
            throw new Error("Failed to retrieve valuations. Status: " + valuationsResponse.status);
        }

        return valuations;

    } catch (error) {
        throw error;
    }
}

export async function deleteValuation(id) {
    const accessToken = await getValuationApiAccessToken();

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${accessToken}`);
    const requestOptions = {
        method: 'DELETE',
        headers: headers
    }

    try {
        const deleteResponse = await fetch(`${process.env.REACT_APP_API}/${id}`, requestOptions);

        if (!deleteResponse.ok) {
            throw new Error("Failed to delete valuation. Status: " + deleteResponse.status);
        }
    } catch (error) {
        throw error;
    }
}

function mapValuation(formData, phoneNumber, phoneCountryCode, user, isTablet, sendEmail, edit) {
    return {
        id: formData.id,
        Firm: formData.firm,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Phone: phoneNumber ? parseInt(phoneNumber) : null,
        PhoneCountryCode: phoneCountryCode,
        // this is the email to the customer, not real estate agent
        Email: formData.eMail,
        rent: formData.rent ? parseInt(formData.rent) : null,
        RentHighEstimate: formData.rentHighEstimate ? parseInt(formData.rentHighEstimate) : null,
        Bnr: formData.bnr ? parseInt(formData.bnr) : null,
        Gnr: formData.gnr ? parseInt(formData.gnr) : null,
        Fnr: formData.fnr ? parseInt(formData.fnr) : null,
        Snr: formData.snr ? parseInt(formData.snr) : null,
        Anr: formData.anr ? parseInt(formData.anr) : null,
        Lnr: formData.lnr,
        UPN: user.userPrincipalName,
        Address: formData.address,
        AddressNumber: formData.addressNumber,
        AddressLetter: formData.addressLetter,
        PostalArea: formData.postalArea,
        PostalNumber: formData.postalNumber,
        IsTablet: isTablet ? 1 : 0,
        Edit: edit,
        MailToBroker: sendEmail
    };
}

async function getValuationApiAccessToken() {
    return await getAccessToken([`api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`]);
}