import './OfficePicker.css'

function OfficePicker({ officeList, setActiveOffice, activeOffice, setActiveUser, setUserList }) {

    const handleChangeOffice = (office) => {
        setActiveOffice(office)
        setActiveUser(null)
        setUserList(null)
    }

    return (
        <div className='OfficePicker'>
            <p className="OfficePicker--radiogroup-title">Velg kontor</p>
            <div className='OfficePicker--row'>
                <label className="OfficePicker--office">
                    <input
                        onChange={() => handleChangeOffice(null)}
                        type='radio'
                        checked={activeOffice === null || activeOffice === undefined}
                    />
                    Alle kontorer
                </label>
            </div>
            {officeList.map(office => {
                return (
                    <div className='OfficePicker--row' key={office}>
                        <label className="OfficePicker--office">
                            <input
                                onChange={() => handleChangeOffice(office)}
                                type='radio'
                                checked={office === activeOffice}
                            />
                            {office}
                        </label>
                    </div>
                )
            })}
        </div>
    )
}

export default OfficePicker;