import React, { useState, useEffect } from 'react';

function DropDown({ items, submitted, initialValue, onItemSelect, id }) {

    const [inputValue, setInputValue] = useState(initialValue || '');

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        onItemSelect(newValue);
    };

    useEffect(() => {
        setInputValue(initialValue);
    }, [initialValue]);

    if (!items) {
        return (
            <div className='valuation-form--input'>
                <input
                    data-1p-ignore
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    className='Valuation__form__field'
                    disabled={submitted}
                />
            </div>
        );
    }

    return (
        <div className={`valuation-form--input ${items ? 'valuation-form--input--with-dropdown' : ''}`}>
            <input
                data-1p-ignore
                type="text"
                id={id}
                value={inputValue}
                onChange={handleInputChange}
                list={`${id}-list`}
                className='Valuation__form__field'
                disabled={submitted}
            />

            <datalist id={`${id}-list`}>
                {items.map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                ))}
            </datalist>
        </div>
    );
}

export default DropDown;