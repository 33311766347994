import './DeleteScreen.css';
import { deleteValuation } from '../lib/valuationsService';

const DeleteScreen = ({ val, setDelete }) => {

    const DeleteValuation = async () => {
        if (!val.id) return;
        await deleteValuation(val.id);
        setDelete();
    }

    return (
        <div className="Delete-background" onClick={() => setDelete()}>
            <div className="Delete-body">
                <h3>Er du sikker på at du vil slette dette leieprisestimatet?</h3>
                <p>Denne handlingen er irreversibel - det vil ikke være mulig å gjenopprette det som blir slettet</p>
                <p>Leien for denne er {val && val.rent} </p>
                <div className="Delete--container">
                    <button className="Delete--button" onClick={() => DeleteValuation()}>
                        <p>Slett</p>
                    </button>
                    <button className="Delete--button" onClick={() => setDelete()}>
                        <p>Ta meg tilbake</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeleteScreen;