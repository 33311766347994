import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MenuButton } from './MenuButton';

export const BurgerMenu = ({ open, setOpen }) => {

  const node = useRef();

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
      };
        // eslint-disable-next-line
    }, [handler]);
  };

  useOnClickOutside(node, () => {
    setOpen(false);
  });

  function onKeyPressed(e) {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <MenuButtonContainer onClick={() => setOpen(!open)} onKeyDown={onKeyPressed} ref={node} tabIndex="0">
      <MenuButton
        isOpen={open}
        aria-label="menu navigation button"
        onClick={() => setOpen(!open)}
        strokeWidth="2"
        color="black"
        transition={{ ease: 'easeOut', duration: 0.2 }}
        width="24"
        height="20"
      />
    </MenuButtonContainer>
  );
};

const MenuButtonContainer = styled.button`
  z-index: 1001;
  position: absolute;
  right: 2rem;
  margin: 3rem 0;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline-offset: 5px;
  }
`;
