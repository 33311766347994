import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

export default function Drawer({ setOpen, open }) {
    function onKeyPressed(e) {
        if (e.key === 'Escape') {
            setOpen(false);
        }
    }

    return (
        <DrawerContainer open={open} onKeyDown={onKeyPressed}>
            <DrawerOverLay />
            <StyledDrawer>
                <ListGroup>
                    <ListItem>
                        <Link to={`leieprisestimater`}>Leieprisestimater</Link>
                    </ListItem>
                    <ListItem>
                        <Link to={`nytt-estimat`}>Opprett ny Leieprisestimat</Link>
                    </ListItem>
                </ListGroup>
            </StyledDrawer>
        </DrawerContainer>
    );
}

const ListGroup = styled.ul`
  margin: 6rem 0;
  &:first-of-type {
    margin-top: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  z-index: ${({ open }) => (open ? '200' : '0')};
  visibility: ${({ open }) => (open ? 'initial' : 'hidden')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  min-height: 100vh;
  text-align: left;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  overflow-y: hidden;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
`;

const DrawerOverLay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  width: calc(100% - 40%);
  min-height: 100vh;
`;

const StyledDrawer = styled.nav`
  position: absolute;
  min-height: 100vh;
  padding-top: 15.8rem;
  background-color: black;
  width: 100%;

  @media (min-width: 1024px) {
    border-left: 2px #FEC359;
    left: 60%;
    width: calc(100% - 60%);
  }

  ul {
    padding: 0;
    list-style: none;
  }
`;

const ListItem = styled.li`
  padding-left: 9rem;
  margin-bottom: 1rem;
  /* padding-left: 2rem; */
  min-height: 3.2rem;

  &:hover {
    cursor: pointer;
  }

  &:hover {
    a {
      transition: all 0.1s ease-in-out;
      border-bottom: 2px solid #FEC359;
      color: #FEC359;
      margin-left: 1rem !important;
    }
  }

  a {
    color: white;
    font-size: 3rem;
    line-height: 3rem;
    text-decoration: none;
    font-size: ${(props) => (props.small ? '1.6rem' : '2rem')};
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 400;

    &:focus-visible {
      outline: none;
      border-bottom: 2px solid #FEC359;
      color: #FEC359;
      margin-left: 1rem !important;
    }
  }
`;
