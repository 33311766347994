
const baseUrl = "https://ws.geonorge.no";
const addressSearchEndpoint = "adresser/v1/sok";

export async function searchAddresses(searchString) {
    const url = `${baseUrl}/${addressSearchEndpoint}?sok=${searchString}&fuzzy=false&utkoordsys=4258&treffPerSide=10&side=0&asciiKompatibel=true`;

    try {
        const addressesResponse = await fetch(url);
        const addresses = await addressesResponse.json();
        
        if (!addressesResponse.ok) {
            throw new Error("Failed to retrieve addresses. Status: " + addressesResponse.status);
        }

        return addresses.adresser;

    } catch (error) {
        throw error;
    }
}