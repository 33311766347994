import { getAccessToken } from "./tokenService";

const graphBaseUrl = "https://graph.microsoft.com/v1.0";

export async function getUser() {
    const accessToken = await getGraphAccessToken();

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    try {
        const userResponse = await fetch(`${graphBaseUrl}/me`, requestOptions);
        const user = await userResponse.json();

        if (!userResponse.ok) {
            throw new Error("Failed to retrieve user with status " + userResponse.status);
        }

        return user;
    } catch (error) {
        throw error;
    }
}

export async function getUserPhoto() {
    const accessToken = await getGraphAccessToken();
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'image/jpeg');
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    try {
        const userPhotoResponse = await fetch(`${graphBaseUrl}/me/photo/$value`, requestOptions);
        const userPhotoBlob = await userPhotoResponse.blob();

        if (!userPhotoResponse.ok) {
            throw new Error("Failed to retrieve user photo with status " + userPhotoResponse.status);
        }

        const photo = await convertBlobToBase64(userPhotoBlob);
        return photo;
    } catch (error) {
        throw error;
    }
}

function convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
}

async function getGraphAccessToken() {
    return await getAccessToken(["User.Read"]);
}