import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_CLIENT_ID}`,
        authority: "https://login.microsoftonline.com/63d3cb80-247f-46b1-ae9a-e84bf784f411",
        redirectUri: `${process.env.REACT_APP_URL}`
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
}

export const msalInstance = new PublicClientApplication(msalConfig);