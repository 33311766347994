import { Link } from "react-router-dom";
import './CallToActionButton.css'

const CallToActionButton = ({ text, link }) => {
    return (
        <Link to={link}>
            <button className='action--button' > <p>{text}</p> </button>
        </Link> 
    )
}

export default CallToActionButton;