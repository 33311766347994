import React, { useState, useEffect } from 'react';
import './SuccessScreen.css';

const SuccessScreen = ({ submitted, setSubmitted, valuationUrl, response }) => {

    const [countdown, setCountdown] = useState(0);
    const [errorObject, setErrorObject] = useState(false);
    useEffect(() => {
        if (submitted && countdown === 0) {
            setCountdown(10);
        }
    }, [submitted]);

    useEffect(() => {
        let time = 1000;
        const timeout = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            };
        },
            time);
        return () => {
            // clears timeout before running the new effect
            clearTimeout(timeout);
        }
    }, [countdown]);

    useEffect(() => {
        // Because some error responses come back with error (singular) and others with errors(plural) this will correctly put any errors into the state for rendering
        if (response.error) { setErrorObject(response.error) }
        if (response.errors) { setErrorObject(response.errors) }
    }, [response]);

    return (
        <div id={submitted ? 'loading__background' : 'loading__background__hidden'}>
            {!valuationUrl && !errorObject && <p>Våre maskiner jobber med å lage ditt leieprisestimat - Ikke gå noe sted, det tar ca {countdown} sekunder</p>}
            {valuationUrl &&
                <div className="success-container">
                    <p>Du kan nå se ditt leieprisestimat her: </p>
                    <a target="_blank" className="pdf-link--button" rel="noopener noreferrer" href={valuationUrl}><p>Trykk her</p></a>
                </div>
            }
            {errorObject && <div className="success-container--failed">
                <h3>Vi beklager, en feil har oppstått med å opprette ditt leieprisestimat</h3>
                <p>Vennligst meld ifra til support, kopier og send inn feilmeldingen under </p>
                <div className="success-container--error-message">
                    <p>{response.status} error: {response.title}</p>
                    <p>
                        {JSON.stringify(response, null, 2)}
                    </p>
                </div>
            </div>}
        </div>
    )
}

export default SuccessScreen;