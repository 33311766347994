import { React } from 'react';
import PhoneInput from 'react-phone-input-2';

function PhoneInputField({ setPhoneNumber, phoneNumber, phoneCountryCode, setPhoneCountryCode, submitted }) {
    const phoneValidationHelper = e => {
        // Because we store the phone number as unsigned long we need to guard against overflow and non-numeric (0-9) input
        let valid = true;
        const validInput = /^[0-9]*$/;

        if (e.toString().match(validInput) === null) {
            valid = false;
        }

        if (e > 18446744073709551614) {
            valid = false;
        }

        // Because the database will not save leading 0s we do not allow the user to enter them, to make sure what they send in is what will be saved
        if (e[0] === "0") {
            valid = false;
        }

        if (valid || e === '') {
            setPhoneNumber(e);
        }

        if (!valid) {
            alert('Du må skrive inn et gyldig tall som ikke starter med 0. Symboler som + godkjennes ikke. Trykk på flagget for å velge et annet retningsnummer.');
        }
    }

    const focusInput = () => {
        document.getElementById("phone-number").focus();
    }

    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    return (
        <div className="special-phone--container">
            <div className='valuation-form--phone-input'>
                <PhoneInput
                    disabled={submitted}
                    country={'no'}
                    onlyCountries={['no', 'dk', 'se', 'fi']}
                    value={phoneCountryCode && phoneCountryCode}
                    placeholder={''}
                    placeHolder={''}
                    onKeyDown={e => e.preventDefault(e)}
                    onClick={() => focusInput()}
                    onChange={e => setPhoneCountryCode(`+${e}`)}
                />
            </div>
            <div className='valuation-form--input'>
                <label htmlFor='phone-number'>Mottaker: Tlf</label> <input id='phone-number' type='numeric' disabled={submitted} value={phoneNumber} className='Valuation__form__field'
                    onChange={e => { phoneValidationHelper(e.target.value) }}
                    onKeyDown={blockInvalidChar}
                />
            </div>
        </div>
    )
}

export default PhoneInputField;